<template>
  <b-card>
    <b-row class="">
      <b-col class="col-md-12">
        <h2
          class="text-blue font-weight-bold col-md-10 col-lg-10 col-xl-11 mt-1"
        >
          {{ $t("additional_documents") }}
        </h2>
      </b-col>
    </b-row>
    <div v-if="!isLoading">
      <Table
        :body="{}"
        :fields="fields"
        :showSearch="false"
        :url="'/additional-docs/admin/list'"
      >
        <template #cell(created_at)="data">
          {{ data.item.created_at | formatDate }}
        </template>
        <template #cell(id)="data">
          <p  style="width:55px;" class="badge badge-light-primary">{{ data.value }}</p>

        </template>
        <template #cell(company)="data">
          <span v-if="data.item.company">{{ data.item.company.name }} </span>
          <span v-else></span>
        </template>
        <template #cell(status)="data">
          <span class="text-secondary" v-if="data.value == 0"
            >{{ $t("pending") }}
          </span>
          <span class="text-success" v-if="data.value == 1">{{
            $t("approved")
          }}</span>
          <span class="text-danger" v-if="data.value == 2">{{
            $t("declined")
          }}</span>
          <span class="text-secondary" v-if="data.value == 3">{{
            $t("proof_of_ownership_is_in_progress")
          }}</span>
        </template>
        <template #cell(title)="data">
          {{ data.item.title }}
        </template>
        <template #cell(custom)="data">

        <div style="display: inline-flex;">
          <b-button
          v-b-tooltip.hover :title="$t('read_pdf')"
          class=" button-top-m web"
          @click="readPdf(data.item.id, data.item)"
          variant="outline-success"
          size="sm"
        >
          <FeatherIcon icon="BookOpenIcon"></FeatherIcon>
        </b-button>
        <b-button
        v-b-tooltip.hover :title="$t('approve')"
          variant="outline-secondary"
          size="sm"
          class="button-top-m"
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          v-b-modal.approveModal
          @click="
            changeModalInfo(
              'approve',
              'Approve',
              'Are you sure?',
              data.item.id
            )
          "
        >
          <feather-icon icon="CheckIcon"></feather-icon>
        </b-button>
        <b-button
        v-b-tooltip.hover :title="$t('decline')"
          variant="outline-danger"
          size="sm"
          class="button-top-m"
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          v-b-modal.approveModal
          @click="
            changeModalInfo(
              'decline',
              'Decline',
              'Are you sure?',
              data.item.id
            )
          "
        >
          <feather-icon icon="XIcon"></feather-icon>
        </b-button>
        <b-button
        v-b-tooltip.hover :title="$t('delete')"
          variant="danger"
          size="sm"
          class="button-top-m"
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          v-b-modal.approveModal
          @click="
            changeModalInfo('delete', 'Delete', 'Are you sure?', data.item.id)
          "
        >
          <feather-icon icon="TrashIcon"></feather-icon>
        </b-button>
        </div>

        </template>
      </Table>
    </div>
    <CustomLoader :show="isLoading" />
    <ApproveModal :textContent="modalText" :title="modalTitle" />
  </b-card>
</template>

<script>
import {
  BTable,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BPagination,
  BTr,
  BTh,
  BButton,
  BLink,
} from "bootstrap-vue";
import fileDownload from "js-file-download";
import Ripple from "vue-ripple-directive";
import ApproveModal from "@/components/Common/ApproveModal";
import CustomLoader from "@/components/Common/CustomLoader";
import Table from "@/components/Common/Table2.vue";

export default {
  name: "Documents",
  components: {
    ApproveModal,
    BTable,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BPagination,
    BTr,
    BTh,
    BButton,
    BLink,
    CustomLoader,
    Table,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      modalText: null,
      modalTitle: null,
      options: null,
      loadPdf: true,
      fields1: [
        { label: "title", align: "start", key: "title", sortable: false },
        { label: "", customSlot: false, key: "custom", class: "text-right" },
      ],
      fields: [
        { label: "created_at", align: "start", key: "created_at", sortable: true },
        { label: "id", align: "start", key: "id", sortable: true },
        { label: "company", align: "start", key: "company", sortable: false },
        { label: "title", align: "start", key: "title", sortable: false },
        { label: "status", key: "status", customSlot: true, sortable: false },
        { label: "", customSlot: false, key: "custom", class: "text-right" },
      ],
      items: [],
      search: "",
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      embedType: "",
      src: "",
      status: [],
      isLoading: false,
      totalItems: 0,
      approveModalAction: null,
      item: null,
      showPdfViwer: false,
      type: "",
      base64: "",
      source: null,
    };
  },
  created() {
    this.getApiData();
  },
  watch: {
    sideBar(newValue, oldValue) {
      this.showPdfViwer = false;
    },
  },
  computed: {
    rows() {
      return this.totalItems;
    },
    sideBar() {
      return this.$store.state.app.sidebar;
    },
  },
  methods: {
    readPdf(id, item) {
      this.item = item;

      this.$store.commit("app/ON_SIDEBAR_CLICK", false);

      this.loadPdf = true;
      this.type = "";
      this.base64 = "";
      this.src = "";
      this.embedType = "";

      this.$http
        .post("/additional-docs/admin/read/" + id, {
          responseType: "json",
        })
        .then((response) => {
          this.base64 = response.data.value.b64;
          this.type = response.data.value.ext;
          if (this.type == "pdf") {
            this.src =
              "data:application/" +
              this.type +
              ";base64," +
              this.base64 +
              "#toolbar=0";
            this.embedType = "application/" + this.type;

            this.loadPdf = false;
          } else {
            this.src =
              "data:image/" +
              this.type +
              ";base64," +
              this.base64 +
              "#toolbar=0";
            this.embedType = "image/" + this.type;

            this.loadPdf = false;
          }
          this.$store.commit("app/SET_FILE_TYPE", this.type);
          this.$store.commit("app/SET_FILE_BASE64", this.base64);

          window.open("/read-files", "_blank");
        });
    },
    changeModalInfo(action, title, text, id) {
      this.approveModalAction = action;
      this.modalText = text;
      this.modalTitle = title;
      this.idToChange = id;
    },
    callApproveModalAction() {
      if (this.approveModalAction == "approve") {
        this.changeStatus(this.idToChange, "approve");
      }
      if (this.approveModalAction == "decline") {
        this.changeStatus(this.idToChange, "decline");
      }
      if (this.approveModalAction == "delete") {
        this.changeStatus(this.idToChange, "delete");
      }
    },
    changeStatus(id, status) {
      this.isLoading = true;
      let s = 0;
      if (status == "approve") {
        s = 1;
      }
      if (status == "decline") {
        s = 2;
      }
      if (status == "delete") {
        s = 99;
      }
      this.$http
        .post("/additional-docs/admin/update/" + id, { status: s })
        .then((response) => {
          this.getApiData();
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
        });
    },
    downloadPdf(id, item) {
      this.$http
        .post(
          "/additional-docs/admin/download/" + id,
          {},
          {
            responseType: "blob",
          }
        )
        .then((response) => {
          fileDownload(response.data, item.title);
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
        });
    },
    getApiData() {
      this.isLoading = true;
      this.items = [];
      this.$http
        .post("/additional-docs/admin/list?page=" + this.page, {
          status: this.status,
          options: null,
          filters: this.filters,
          search: this.search,
        })
        .then((response) => {
          if (response.data.status == 200) {
            this.items = response.data.value.data;
            this.totalItems = response.data.value.total;
            this.page = response.data.value.current_page;
          }
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style>
.button-top-m {
  margin-right: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
}
</style>
